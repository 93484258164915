<template>
<div>
  <div class="stepContent">
    <loader v-if="pageLoading" :loading="pageLoading"/>
    <ValidationObserver ref="observer" v-slot="{handleSubmit}">
      <form v-if="!pageLoading" @submit.prevent="handleSubmit(continueResume)" class="tw-w-8/12 ml-n16">
        <div class="tw-mb-10">
          <h3 class="step-title tw-mt-4 tw-mb-2">Job Preference</h3>
          <span class="profile ">This data will only be used to customize your job search experience</span>
        </div>
        <div class="mt-7">
          <div>
            <small style="color: red" v-if="showError.state">{{ showError.message }}</small>
          </div>
          <span class="job tw-mb-10">Job preference</span>
          <ValidationProvider
              v-slot="{ errors }"
              name="job preference"
              rules="required"
          >
            <br><span class="err">{{ errors[0] }}</span>

            <v-combobox v-model="select"
                        :items="items"
                        label="Enter your job preference"
                        multiple
                        class="tw-pt-10"
                        chips
                        solo>
              <template v-slot:selection="data">
                <v-chip class="tw-flex tw-mt-10"
                        :key="JSON.stringify(data.item)"
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        :disabled="data.disabled"
                        @click:close="data.parent.selectItem(data.item)">
                  {{ data.item }}
                </v-chip>
              </template>
            </v-combobox>

          </ValidationProvider>

        </div>
        <div>
          <span class="search">Job Search Stage</span>
          <p class="profile" style="color: #6b778c "> This will be displayed on your profile.</p>

          <div class="tw-mt-6">
            <p>Only visible only to recruiters</p>
            <div class="tw-flex tw-flex-wrap tw-w-full mb-3 tw-mt-5 tw-cursor-pointer">
              <div v-for="jobCurrentStatus in jobStatus" :key="jobCurrentStatus"
                   @click="handleSelectedEducation(jobCurrentStatus)"
                   :class="[jobCurrentStatus === selectedSearch ? 'selected' : 'select']"
                   class="tw-mr-5 tw-mb-5"
              >
                <span>{{ jobCurrentStatus }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="tw-flex tw-justify-end mb-5 tw-mt-8">
          <base-button
              id="profileContinue"
              class="tw-pt-5"
              button-text="Save Updates"
              width="128"
              :loading="submitLoading"
              :disabled="showbtn"
          >
          </base-button>
        </div>

      </form>
    </ValidationObserver>
  </div>
</div>
</template>

<script>
import BaseButton from "@/components/reusables/BaseButton";
import {mapGetters} from 'vuex'
import Loader from "../../reusables/Loader";
export default {
  name: "JobPreferences",
  components: {BaseButton, Loader},
  data(){
    return{
      pageLoading: false,
      select: [],
      date_of_birth:'',
      location: '',
      about_me: '',
      showbtn: false,
      items: [
        'Product Manager',
        'Product Designer (Ux)',
        'Frontend Software Engineer',
        'Backend Software Engineer',
        'Data Analyst',
        'Content Marketing Associate',
        'Seo Consultant',
        'System Administrator',
        'Visual Designer',
        'Software Training Facilitator',
        'Graphic Designer',
        'Mobile Developer',
        'Full Stack Developer',
        'IT Help Desk Technician',
        'Social Media Officer',
        'Web Developer',
        'Project Management Analyst',
        'Data Analyst',
        'Web Analytics Developer',
        'Digital Marketing Manager',
        'Network Support Engineer',
        'Regional Developer',
        'Content Manager',
        'Content Strategist',
        'Frontend React Native',
        'UI Designer',
        'Wifi Project Engineer',
        'Integration Engineer',
        'QA Engineer',
        'Solution And Research Engineer',
        'Technology Cloud Sales Representative',
        'Java Microservices Engineer',
        'Interaction Designer',
        'DevOps Engineer',
        'Technical Writer',
        'Customer Engagement Manager',
        'Software Developers',
        'WordPress Developer',
        'Frameworks Specialist',
        'Ruby On Rails Developer',
        'Python Developer',
        'Business Systems Analyst',
        'Blockchain Developer',
        'Maintenance Engineer And Machine Operator',
        'Data Architect',
        'Data Modeler',
        'Software Developer',
        'Data Scientist',
        'Cloud Architect',
        'Technical Lead',
        'Devops Manager',
        'Agile Project Manager',
        'Technical Account Manager',
        'Security Specialist',
        'QA (Quality Assurance) Specialist',
        'Game Developer',
        'Computer Graphics Animator',
        'Machine Learning Engineer',
        'Senior Backend Engineer'
      ],
      jobStatus: ['Actively looking', 'Not looking but open to offers', 'Closed to offers'],
      selectedSearch: '',
      submitLoading: false,
      showError:{
        message: "",
        state: false
      }
    }
  },
  computed: {
    ...mapGetters('createProfile', ['profileData'])
  },
  methods: {
    handleSelectedEducation(item) {
      return this.selectedSearch = item
    },
    async continueResume() {
      this.submitLoading = true

      let data = {}
      data.job_preference = this.select
      data.job_search_stage = this.selectedSearch.toLowerCase().split(' ').join('_')
      data.about_me = this.about_me
      data.location = this.location
      data.date_of_birth = this.date_of_birth

      if (data.job_search_stage === '') {
        this.$displaySnackbar({message: 'Select a job search stage', success: false})
        this.submitLoading = false
        return
      }
      if(this.select.length >=4){
        this.showError.state = true
        this.showError.message ="Field exceeds maximum selection of 3"
        this.submitLoading = false
        return false
      }
      else {
        this.showError.message = ""
        this.showError.state = false
      }
      try {
        await this.$store.dispatch('createProfile/applicantProfile', data)
        this.submitLoading = false
        this.$displaySnackbar({message: 'Job preference updated', success: true})
      } catch (e) {
        this.$displaySnackbar({
          message: e.response.data.message,
          success: false
        })
        this.submitLoading = false
      } finally {
        this.submitLoading = false
        this.showbtn = true
      }
    },
    populateData() {
      let data = this.profileData
      if (data) {
        this.select = data.job_preference
        this.date_of_birth = data.date_of_birth
        this.about_me = data.about_me
        this.location = data.location
        this.selectedSearch = data.job_search_stage.replace(data.job_search_stage.charAt(0), data.job_search_stage.charAt(0).toUpperCase())
        console.log(this.selectedSearch)
      }
      this.pageLoading = false
    }
  },
  async created() {
    this.pageLoading = true
    await this.$store.dispatch('createProfile/getApplicantData')
    this.populateData()
  }
}
</script>

<style scoped>
.job {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #1E323F;
}

.search {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #475661;
}

.select-input {
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
  border: none !important;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: DM Sans;
  color: #001343;
  cursor: pointer;
}

.selected {
  padding: 16px 25px 25px;
  width: max-content;
  height: 56px;
  background: #F7FCFF;
  border: 2px solid #008EEF;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #008EEF;
  cursor: pointer;
}

.select {
  padding: 16px 25px 25px;
  width: max-content;
  height: 56px;
  border: 2px solid #D0DCE4;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}

.tit {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  /* Enum-Grey/2 */

  color: #475661;
}

.profile {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}

::v-deep .v-chip.v-size--default {
  box-sizing: border-box;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
</style>