<template>
  <div class="d-flex align-center justify-center">

    <v-progress-circular
        indeterminate
        color="#008EEF"
        class="mt-16"
    />
  </div>
</template>

<script>
export default {
name: "Loader",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>

</style>